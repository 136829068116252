@import '../../variables';
.container{
  height: 100vh;
  width: 100%;

  position: relative;

  #namePopup,  #dataPopup{
    padding: 15px;
    z-index: 999;

    position: absolute;

    transform:translate(-100%,-100%);

    background-color: $sidebar-background;

    border-radius: 11px 11px 11px 11px;
    -moz-border-radius: 11px 11px 11px 11px;
    -webkit-border-radius: 11px 11px 11px 11px;
    border: 0 solid #000000;

    -webkit-box-shadow: 0 0 5px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0 0 5px -3px rgba(0,0,0,0.75);
    box-shadow: 0 0 5px -3px rgba(0,0,0,0.75);

    p{
      margin: 0;
    }

    .hidden{
      opacity: 0;
    }
  }

  #namePopup{

    :first-child{
      font-size: 1.2em;
      font-weight: bolder;
      margin-bottom: 5px;
    }
  }

  .interestMap{
    height: 100%;
    width: 100%;

    canvas{
      width: 100%;
      z-index: 100;
    }
  }

  .interestMap_legend > div:nth-child(2) > div:nth-child(1) > div:nth-child(1){
    background-color: #AF3AF4;
  }
  .interestMap_legend > div:nth-child(2) > div:nth-child(2) > div:nth-child(1){
    background-color:rgba(175,58,244,0.74);
  }
  .interestMap_legend > div:nth-child(2) > div:nth-child(3) > div:nth-child(1){
    background-color:rgba(175,58,244,0.48);
  }
  .interestMap_legend > div:nth-child(2) > div:nth-child(4) > div:nth-child(1){
    background-color:rgba(175,58,244,0.18);
  }
  .interestMap_legend > div:nth-child(2) > div:nth-child(5) > div:nth-child(1){
    background-color:rgba(175,58,244,0);
    border: 1px solid #000;
  }

  .interestMap_legend{
    user-select: none;
    padding: 20px;
    margin: 15px;
    z-index: 999;

    bottom: 0;
    right: 0;

    position: absolute;

    background-color: $white;

    border-radius: 11px 11px 11px 11px;
    -moz-border-radius: 11px 11px 11px 11px;
    -webkit-border-radius: 11px 11px 11px 11px;
    border: 0 solid #000000;

    -webkit-box-shadow: 0 0 5px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0 0 5px -3px rgba(0,0,0,0.75);
    box-shadow: 0 0 5px -3px rgba(0,0,0,0.75);

    p{
      margin: 0;
    }

    >div{
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      >div{
        display: flex;
        align-items: center;

        div:first-child{
          border-radius: 50%;
          margin-right: 5px;
          width: 20px;
          height: 20px;
          /*&:nth-of-type(1){
            background-color: rebeccapurple;
          }*/

          /*&:(1){

          }*/
        }
      }
    }
  }
}
