/* COLORS */
$primary: #af4af3;
$primary-dark: #6D37DB;
$primary-light: #C637DB;
$primary-sidebar-navlink: rgba(62, 100, 255, 0.1);
$accent: #3e64ff;

$white: #FFFFFF;
$text-primary: #212121;
$text-secondary: #AFAFAF;
$text-grey: #4A4A4A;
$text-light: #E5E5E5;
$text-lighter: #FFFFFF;
$divider: #BDBDBD;

$dark: #212121;
$black: #000000;

$sidebar-background: #fafafa;

/* DIMENSIONS */
$sidebarWidth: 250px;
$sidebarCollapsedWidth: 80px;