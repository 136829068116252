@import '../../variables';

.sidebar {
    position: fixed;
    width: $sidebarWidth;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
    transition: width, left, right, 0.3s;
    background-color: $sidebar-background;

    &.collapsed {
        width: $sidebarCollapsedWidth !important;
        min-width: $sidebarCollapsedWidth !important;

        .sidebar-header-logo svg path:nth-of-type(1) {
            transform: translateX(-42%);
            -webkit-transform: translateX(-42%);
            -moz-transform: translateX(-42%);
            -ms-transform: translateX(-42%);
            -o-transform: translateX(-42%);
        }

        .sidebar-header-logo svg path:nth-of-type(3) {
            transform: translateX(-25%);
            -webkit-transform: translateX(-25%);
            -moz-transform: translateX(-25%);
            -ms-transform: translateX(-25%);
            -o-transform: translateX(-25%);
        }
    }

    &-header {
        padding: 44px 26px;
        text-align: left;

        &-logo {
            font-size: 2em;
            cursor: pointer;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;

            * {
                color: $primary;
                transition: 300ms;
                -webkit-transition: 300ms;
                -moz-transition: 300ms;
                -ms-transition: 300ms;
                -o-transition: 300ms;
}
        }
    }

    ul,
    li {
        text-decoration: none;
        list-style-type: none;
        padding: unset;
        margin: unset;
    }

    a {
        display: block;
        text-decoration: none;
        color: $text-secondary;
        padding: 10px 26px;
        margin: 0 0 10px 0;
        border-radius: 0 25px 25px 0;
        box-sizing: border-box;
        width: 92%;
        overflow: hidden;
        position: relative;

        .navLinkIcon {
            color: $text-secondary;
            margin-right: 1.8em;
            margin-bottom: -2px;
            font-size: 1.1em;
        }

        .navLinkText,
        .navLinkChevron {
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
        }

        .navLinkText {
            position: absolute;
            width: 70%;
            font-family: 'Ubuntu', 'Roboto', 'Segoe UI', 'Arial', sans-serif;
        }

        .navLinkChevron {
            opacity: 0;
            position: absolute;
            top: 50%;
            right: 1em;
            z-index: 2;
            transition: 0ms;
            -webkit-transition: 0ms;
            -moz-transition: 0ms;
            -ms-transition: 0ms;
            -o-transition: 0ms;
        }

        &.active,
        &.active .navLinkIcon,
        &.active .navLinkChevron {
            color: $primary;
        }

        &.active {
            background: $primary-sidebar-navlink;

            .navLinkChevron {
                opacity: 1;
            }
        }
    }
}


@media only screen and (max-width: 720px) {
    .sidebar {
        overflow: hidden;
    }
}