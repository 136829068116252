@import '../../variables';

#heatMapBoxContainer {
  user-select: none;
  padding: 15px;
  margin: 15px;
  z-index: 999;

  bottom: 3%;
  left: 5px;

  position: absolute;

  background-color: $white;

  border-radius: 11px 11px 11px 11px;
  -moz-border-radius: 11px 11px 11px 11px;
  -webkit-border-radius: 11px 11px 11px 11px;
  border: 0 solid #000000;

  -webkit-box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.75);

  p {
    margin: 0 0 15px 0;
    padding: 0;
  }
  
  h3 {
    margin: 0;
  }

  .switch {
    --line: rgba(175, 175, 175, 0.34);
    --dot: rgba(175, 74, 243, 0.56);
    --circle: #AFAFAF;
    --duration: .3s;
    --text: #00000;
    cursor: pointer;
    margin: 1px;

    input {
      display: none;

      &+div {
        position: relative;

        &:before,
        &:after {
          --s: 1;
          content: '';
          position: absolute;
          height: 3px;
          top: 10px;
          width: 24px;
          background: var(--line);
          transform: scaleX(var(--s));
          transition: transform var(--duration) ease;
        }

        &:before {
          --s: 0;
          left: 0;
          transform-origin: 0 50%;
          border-radius: 2px 0 0 2px;
        }

        &:after {
          left: 28px;
          transform-origin: 100% 50%;
          border-radius: 0 2px 2px 0;
        }

        span {
          padding-left: 56px;
          line-height: 24px;
          color: var(--text);

          &:before {
            --x: 0;
            --b: var(--circle);
            --s: 4px;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            box-shadow: inset 0 0 0 var(--s) var(--b);
            transform: translateX(var(--x));
            transition: box-shadow var(--duration) ease, transform var(--duration) ease;
          }

          &:not(:empty) {
            padding-left: 64px;
          }
        }
      }

      &:checked {
        &+div {
          &:before {
            --s: 1;
          }

          &:after {
            --s: 0;
          }

          span {
            &:before {
              --x: 28px;
              --s: 12px;
              --b: var(--dot);
            }
          }
        }
      }
    }
  }

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  * {
    box-sizing: inherit;

    &:before,
    &:after {
      box-sizing: inherit;
    }
  }
}