@import '../../variables';

.heatmap {
    &-header {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        right: 0;
        z-index: 22;
        background: #fff;
        border-radius: 20px;
        margin: 20px;
        padding: .7em 1.4em 1em 1.4em;
        display: flex;
        flex-direction: column;
        opacity: 0.8;

        h1 {
            display: block;
            margin: 0;
            margin-bottom: .3em;
            padding: 0;
        }
    }

    &-options-months {
        margin-left: 2em;
    }

    &-container {
        position: relative;

        .heatMapTouristes {
            // display: none;
            min-height: 100vh;
            width: 100%;
            position: relative;
        }

        #namePopup {
            :first-child {
                font-size: 1.2em;
                font-weight: bolder;
                margin-bottom: 5px;
            }
        }

        #namePopup,
        #dataPopup {
            padding: 15px;
            z-index: 999;

            position: absolute;

            transform: translate(-100%, -100%);

            background-color: $sidebar-background;

            border-radius: 11px 11px 11px 11px;
            -moz-border-radius: 11px 11px 11px 11px;
            -webkit-border-radius: 11px 11px 11px 11px;
            border: 0 solid #000000;

            -webkit-box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.75);
            box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.75);

            p {
                margin: 0;
            }

            .hidden {
                opacity: 0;
            }
        }
    }

    .heatmap-legend {
        user-select: none;
        padding: 20px;
        margin: 15px;
        z-index: 999;

        bottom: 2vh;
        right: 0;

        position: absolute;

        background-color: $white;

        border-radius: 11px 11px 11px 11px;
        -moz-border-radius: 11px 11px 11px 11px;
        -webkit-border-radius: 11px 11px 11px 11px;
        border: 0 solid #000000;

        -webkit-box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.75);
        box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.75);

        &-inner {
            display: flex;
            flex-direction: column;
        }

        p {
            margin: 0;
        }

        >div {
            margin: 20px 0;
            display: flex;
            justify-content: space-between;

            >div {
                display: flex;
                align-items: center;

                div:first-child {
                    border-radius: 50%;
                    margin-right: 5px;
                    width: 20px;
                    height: 20px;
                    /*&:nth-of-type(1){
                background-color: rebeccapurple;
              }*/

                    /*&:(1){
    
              }*/
                }
            }
        }
    }

    .heatmap-legend>div:nth-child(2)>div:nth-child(1)>div:nth-child(1) {
        background-color: rgba(175, 58, 244, 1.0);
        ;
    }

    .heatmap-legend>div:nth-child(2)>div:nth-child(2)>div:nth-child(1) {
        background-color: rgba(175, 58, 244, 0.9);
    }

    .heatmap-legend>div:nth-child(2)>div:nth-child(3)>div:nth-child(1) {
        background-color: rgba(175, 58, 244, 0.8);
    }

    .heatmap-legend>div:nth-child(2)>div:nth-child(4)>div:nth-child(1) {
        background-color: rgba(175, 58, 244, 0.7);
    }

    .heatmap-legend>div:nth-child(2)>div:nth-child(5)>div:nth-child(1) {
        background-color: rgba(175, 58, 244, 0.6);
    }

    .heatmap-legend>div:nth-child(2)>div:nth-child(6)>div:nth-child(1) {
        background-color: rgba(175, 58, 244, 0.5);
    }

    .heatmap-legend>div:nth-child(2)>div:nth-child(7)>div:nth-child(1) {
        background-color: rgba(175, 58, 244, 0.4);
    }

    .heatmap-legend>div:nth-child(2)>div:nth-child(8)>div:nth-child(1) {
        background-color: rgba(175, 58, 244, 0.3);
    }

    .heatmap-legend>div:nth-child(2)>div:nth-child(9)>div:nth-child(1) {
        background-color: rgba(175, 58, 244, 0.2);
    }

    .heatmap-legend>div:nth-child(2)>div:nth-child(10)>div:nth-child(1) {
        background-color: rgba(175, 58, 244, 0.1);
    }
}