@import './variables';

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: 'Roboto', 'Segoe UI', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ________ Custom Code starts here ________ */
/* Global classes */
.hidden {
  visibility: hidden;
}

.d-none {
  display: none;
}

.v-center {
  vertical-align: middle;
  align-items: center;
}

.flex {
  display: flex;
}

.flex.inline {
  flex-direction: row;
}

.flex.space-evenly {
  justify-content: space-evenly;
}

.flex.space-between {
  justify-content: space-between;
}

/* Page */
.page {
  margin-left: $sidebarWidth;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;

  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  h1 {
    font-family: 'Ubuntu', 'Roboto', 'Segoe UI', 'Arial', sans-serif;
  }

  &-content {
    height: 100vh;

    &-header {
      padding-left: 2em;
      padding-bottom: 1em;
    }
  }
}

.expanded {
  transition: width, left, right, 0.3s;
  margin-left: $sidebarCollapsedWidth !important;
}